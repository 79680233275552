import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_auth = _resolveComponent("auth")!

  return (_openBlock(), _createBlock(_component_auth, {
    title: _ctx.title,
    "submit-button-text": _ctx.submitButtonText,
    "submit-button-loading-text": _ctx.submitButtonLoadingText,
    loading: _ctx.loading,
    successMsg: _ctx.successMessage,
    errorMessage: _ctx.errorMessage,
    onOnSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      (_ctx.resetState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_base_form_group, {
              "input-type": "password",
              required: "",
              label: _ctx.trans('password_reset.password'),
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
              invalidMessage: _ctx.validationErrors.password
            }, null, 8, ["label", "modelValue", "invalidMessage"]),
            _createVNode(_component_base_form_group, {
              "input-type": "password",
              required: "",
              label: _ctx.trans('password_reset.password_confirmation'),
              modelValue: _ctx.password_confirmation,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password_confirmation) = $event)),
              invalidMessage: _ctx.validationErrors.password_confirmation
            }, null, 8, ["label", "modelValue", "invalidMessage"])
          ]))
        : (_openBlock(), _createBlock(_component_base_form_group, {
            key: 1,
            required: "",
            label: _ctx.trans('login.email'),
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
            invalidMessage: _ctx.validationErrors.email
          }, null, 8, ["label", "modelValue", "invalidMessage"]))
    ]),
    _: 1
  }, 8, ["title", "submit-button-text", "submit-button-loading-text", "loading", "successMsg", "errorMessage", "onOnSubmit"]))
}